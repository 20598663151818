import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {StoreService} from "./store.service";


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})


export class ValidatorsService {

  constructor(private storeService: StoreService) {
  }

  checkMatchPassword(passwordControlName: any, newPasswordControlName: any): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const pass = currentControl.get(passwordControlName)?.value;
      const confirmPass = currentControl.get(newPasswordControlName)?.value;
      return pass === confirmPass ? null : {notSame: true};
    };

  }

  required(skipWhiteSpaceValidation?: any): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      let isValid;
      if (currentControl.value !== null && currentControl.value !== undefined) {
        if (currentControl.value === false || currentControl.value === true) {
          isValid = true;
        } else {
          if (skipWhiteSpaceValidation === true) {
            isValid = String(currentControl.value).length > 0;
          } else {
            isValid = String(currentControl.value).trim().length > 0;
          }
        }

      } else {
        isValid = false;
      }
      if (!isValid) {
        return {
          required: {
            valid: false
          }
        }
      } else {
        return null;
      }
    };
  }

  coefficient(coefficientParameter: any): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const value = parseInt(currentControl.value, 10);
      if (value % coefficientParameter !== 0) {
        return {
          coefficient: {
            coefficientParameter,
            valid: false
          }
        };
      } else {
        return null;
      }

    };
  }

  minLength(minLength: any, skipWhiteSpaceValidation?: any): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      let isValid;
      if (skipWhiteSpaceValidation === true) {
        isValid = String(currentControl.value).length >= minLength;
      } else {
        isValid = String(currentControl.value).trim().length >= minLength;
      }
      if (!isValid) {
        return {
          minlength: {
            actualLength: skipWhiteSpaceValidation ? String(currentControl.value).length : String(currentControl.value).trim().length,
            requiredLength: minLength,
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  maxLength(maxLength: any, skipWhiteSpaceValidation?: any): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      let isValid;
      if (skipWhiteSpaceValidation === true) {
        isValid = String(currentControl.value).length <= maxLength;
      } else {
        isValid = String(currentControl.value).trim().length <= maxLength;
      }
      if (!isValid) {
        return {
          maxLength: {
            actualLength: skipWhiteSpaceValidation ? String(currentControl.value).length : String(currentControl.value).trim().length,
            requiredLength: maxLength,
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  mail(): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const emailRegEx: RegExp = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
      const isValid = emailRegEx.test(currentControl.value);
      if ((currentControl.value !== null && currentControl.value !== '') && !isValid) {
        return {
          incorrectMailFormat: {
            valid: false
          }
        };
      } else {
        return null;
      }
    }
  }

  mobile(): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      if (currentControl.value !== null && currentControl.value !== '') {
        const value: string = String(currentControl.value);
        let first3: any;
        first3 = value.substr(0, 3);
        let prefixes: any;
        this.storeService.getPrefixes().pipe(untilDestroyed(this)).subscribe(res => {
          prefixes = res;
        })
        if (prefixes.includes(first3) && (value.length === 10)) {
          return null;
        } else {
          return ({
            incorrectMobile: {
              valid: false
            }
          });
        }
      } else {
        return null;
      }
    }
  }

  nationalId(): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      let code = currentControl.value;
      const sameNumber = code === '0000000000'
        || code === '1111111111'
        || code === '2222222222'
        || code === '3333333333'
        || code === '4444444444'
        || code === '5555555555'
        || code === '6666666666'
        || code === '7777777777'
        || code === '8888888888'
        || code === '9999999999';
      const valueLength = code ? code.length : 0;
      if (valueLength === 0) {
        return null;
      }
      if (valueLength < 10 || parseInt(code, 10) === 0 || sameNumber) {
        return {
          nationalId: {
            valid: false,
          }
        };
      }
      code = ('0000' + code).substr(valueLength + 4 - 10);
      if (parseInt(code.substr(3, 6), 10) === 0) {
        return {
          nationalId: {
            valid: false,
          }
        };
      }
      const checkCode = parseInt(code.substr(9, 1), 10);
      let result = 0;
      for (let i = 0; i < 9; i++) {
        result += parseInt(code.substr(i, 1), 10) * (10 - i);
      }
      result = result % 11;
      if ((result < 2 && checkCode === result) || (result >= 2 && checkCode === (11 - result))) {
        return null;
      } else {
        return {
          nationalId: {
            valid: false,
          }
        };
      }
    }
  }

  certainLength(length: number): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const isValid = String(currentControl.value).length === length;
      if (!isValid) {
        return {
          certainLength: {
            length,
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  password(): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      // const passwordRegEx: RegExp = new RegExp('^(?=^.{8,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\\s)[0-9a-zA-Z!@#$%^&*()]*$');
      let isValid = false;
      if (currentControl.value.length >= 8 && currentControl.value.length <= 32) {
        isValid = true;
      }
      if (!isValid) {
        return {
          incorrectPasswordPattern: {
            valid: false
          }
        };
      } else {
        return null;
      }
    }
  }

  number({min, max}: any): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const value = currentControl.value;
      const price = parseInt(currentControl.value);
      if (isNaN(price)) {
        return {
          onlyNumbers: {
            valid: false
          }
        };
      } else {
        if (price > max) {
          return {
            inValidMax: {
              max,
              price,
              valid: false
            }
          };
        } else if (price < min) {
          return {
            inValidMin: {
              min,
              price,
              valid: false
            }
          };
        } else {
          return null;
        }
      }
    };
  }

  pattern(pattern: RegExp, message?:string): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const value = currentControl.value;
      if(!value) return null;
      const isValid = pattern.test(value);
      if (isValid) {
        return null;
      } else {
        return {
          pattern: {
            valid: false,
            message
          }
        };
      }
    };
  }

  telephone(): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const pattern: RegExp = /^(011|013|017|021|023|024|025|026|028|031|034|035|038|041|044|045|051|054|056|058|061|066|071|074|076|077|081|083|084|086|087)\d{4,8}/;
      const value = currentControl.value;
      const isValid = pattern.test(value);
      if (!isValid) {
        return {
          incorrectFormat: {
            text: "تلفن ثابت",
            valid: false
          }
        };
      } else {
        return null;
      }
    };
  }

  keywordValidation(): ValidatorFn {
    return (currentControl: AbstractControl): { [key: string]: any } | null => {
      const pattern = /^(?=.*[0-9])(?=.*[\u0600-\u06FFa-zA-Z])([\u0600-\u06FFa-zA-Z0-9]+)$/;
      const value = currentControl.value;
      const isValid = pattern.test(value);
      if (!isValid) {
        return {
          keywordValidation: {
            valid: false
          }
        };
      } else {
        if (value.includes('لغو') || value.includes('عضویت')) {
          return {
            keywordValidation: {
              valid: false
            }
          }
        } else {
          return null;
        }
      }
    };
  }


  templateParameter(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pattern = new RegExp('#([^\\s#]+?)#', 'g');
      const isValid = pattern.test(control.value);
      return isValid ? null : { templateParameterValidation: { valid: false } };
    };
  }

}
